import "./index.css";
import imageHeader from "../../../../resources/menu-images/HeadlineText.webp";
import { imageConfig, signatureSubsConfig } from "./config/image-config";
import SubsDetailsAccordion from "./components/part3/accordions";
import LastSection from "./components/last-section";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import LOGO from "../../../../resources/images/bg-logo.webp";
import Typography from "@mui/material/Typography";
import OrderNow from "./components/order-now";
import { Grid } from "@mui/material";
import CHEF_HALF_CIRCLE from "../../../../resources/menu-images/chef-special-logo.webp";
import DISCOVER_TEXT from "../../../../resources/menu-images/discover-text.webp";
import DiscoverItemCard from "./components/discover-item-card";
import DISCOVER_MORE_TEXT from "../../../../resources/menu-images/discover-more-text.webp";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import CommonFooter from "../../../common-footer";

const SubwayMenu = () => {
  const [modalDetails, setModalDetails] = useState(null);
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "30px",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    color: "black",
    border: "none",
    p: 4,
    borderRadius: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "500px",
  };
  return (
    <div className="main-item-menu">
      <div className="item-menu">
        <div className="menu-main-image">
          <img src={imageHeader} width="100%" alt="subway" />
        </div>
        <div className="menu-main-content">
          <h1 className="meet-grill-text"> MEET THE GRILLS</h1>
          <Grid
            container
            md={12}
            sx={{
              mt: { sm: 1, md: 8 },
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {imageConfig.map((item) => {
              return (
                <Grid item md={4} sx={{ mb: { md: 5, xs: 2 } }} sm={4}>
                  <div className="menu-card-con">
                    <img
                      onClick={() => {
                        setModalDetails(item);
                      }}
                      src={item.image}
                      className="menu-img"
                      alt="subway"
                    />
                    <Typography
                      sx={{
                        ml: { md: 3, xxl: 10 },
                        mt: { md: 1, xxl: 5 },
                        fontSize: { md: "25px", xxl: "80px" },
                        fontWeight: 500,
                      }}
                    >
                      {item.text}
                    </Typography>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>

        <div className="menu-half-circle">
          <img
            src={CHEF_HALF_CIRCLE}
            className="half-circle-img"
            alt="subway"
          />
        </div>
      </div>
      <div className="menu-part2">
        <img src={DISCOVER_TEXT} className="discover-text-img" alt="subway" />
        <Grid
          md={12}
          container
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {signatureSubsConfig.map((item) => {
            return (
              <Grid
                item
                md={4}
                sx={{ mb: { md: 4, xs: 1 }, mt: { md: 4, xs: 1 } }}
                sm={4}
              >
                <DiscoverItemCard
                  item={item}
                  setModalDetails={setModalDetails}
                />
              </Grid>
            );
          })}
        </Grid>
        <div className="menu-half-circle craving-more-con">
          <img
            src={DISCOVER_MORE_TEXT}
            className="half-circle-img"
            alt="subway"
          />
        </div>
      </div>
      <SubsDetailsAccordion />
      <LastSection />
      <CommonFooter />
      {modalDetails && (
        <>
          <Modal
            open={!!modalDetails}
            onClose={() => {
              setModalDetails(null);
            }}
            sx={{
              overflow: "scroll",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box sx={style} className="modal-con">
              <div className="modal-cross-div">
                <CloseIcon
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    fontSize: { md: "40px" },
                  }}
                  onClick={() => setModalDetails(null)}
                />
              </div>

              <div>
                <img
                  src={modalDetails.image}
                  className="modal-img"
                  alt="subway"
                />
              </div>
              <div>
                <Typography variant="body1" sx={{ mt: 2 }}>
                  {modalDetails.description}
                </Typography>

                <Typography variant="body1" sx={{ mt: 2 }} color="gray">
                  {modalDetails.ingredients}
                </Typography>
                <Typography sx={{ color: "green" }}>
                  {modalDetails.servingSize}
                </Typography>
              </div>
              <div className="modal-order-btn-con">
                <OrderNow isModal={true} text="START GRILLING ON" />
              </div>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default SubwayMenu;
