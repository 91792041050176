import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../resources/images/bg-logo.webp";
import Unsubscribe from "../unsubscribe";
import { Box, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
const CommonFooter = () => {
  const navigate = useNavigate();
  const [isUnsubscribeModalOpen, setIsUnsubscribeModalOpen] = useState(false);
  return (
    <div>
      <footer className="web-about-footer si-hide-mobile">
        <div className="contact-con">
          <div>
            <h3>CUSTOMER CARE</h3>
            <p
              className="web-yellow-text common-footer-link-ele"
              onClick={() => {
                const emailAddress = "customercare@subway.in";
                window.location.href = `mailto:${emailAddress}`;
              }}
            >
              customercare@subway.in
            </p>
          </div>

          <div className="common-footer-con mb-0">
            <div>
              <p className="common-footer-header yellow-text">Contact</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  const emailAddress = "customercare@subway.in";
                  window.location.href = `mailto:${emailAddress}`;
                }}
              >
                Customer Care
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open("https://feedback.subway.in/talktous", "_blank");
                }}
              >
                Feedback
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => setIsUnsubscribeModalOpen(true)}
              >
                Opt Out
              </p>
            </div>
            <div>
              <p className="common-footer-header yellow-text">Subway Cares</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open(
                    "https://qnet.blob.core.windows.net/central/Nutritional%20%2B%20Allergen%20Chart-%20Version%202.45%20September%20(formatted)%20-%201727331404351.pdf",
                    "_blank"
                  );
                }}
              >
                Nutrition Information
              </p>
            </div>
            <div>
              <p className="common-footer-header yellow-text">Legal</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </p>
            </div>
          </div>
          <div>
            <h3>FOR VENDOR PARTNERSHIP</h3>
            <p className="web-yellow-text">partner@subway.in</p>
          </div>
        </div>

        <div className="common-footer-about-us-border"></div>

        <p style={{ textAlign: "center" }}>
          Registered Address: Eversub India Private Limited, 6th Floor, Vaman
          Centre, Makwana Road Marol, Andheri East, Mumbai-400059, Maharashtra
        </p>
        <p className="footer-text">
          All rights reserved. SUBWAY® is a Registered Trademark of Subway IP
          LLC. ©2022 Subway IP LLC., and is licensed to Eversub India Private
          Limited under an exclusive master franchisee arrangement for the
          territory of the Republic of India.
        </p>

        <img src={logo} className="sm-logo" alt="subway" />
      </footer>
      <footer className="mobile-about-footer si-hide-web">
        <div className="about-us-mobile-common-footer-con">
          <div>
            <p className="common-footer-header yellow-text">Contact</p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => {
                const emailAddress = "customercare@subway.in";
                window.location.href = `mailto:${emailAddress}`;
              }}
            >
              Customer Care
            </p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => {
                window.open("https://feedback.subway.in/talktous", "_blank");
              }}
            >
              Feedback
            </p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => setIsUnsubscribeModalOpen(true)}
            >
              Opt Out
            </p>
          </div>
          <div>
            <p className="common-footer-header yellow-text">Subway Cares</p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => {
                window.open(
                  "https://qnet.blob.core.windows.net/central/Nutritional%20%2B%20Allergen%20Chart-%20Version%202.45%20September%20(formatted)%20-%201727331404351.pdf",
                  "_blank"
                );
              }}
            >
              Nutrition Information
            </p>
          </div>
          <div>
            <p className="common-footer-header yellow-text">Legal</p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => {
                navigate("/privacy-policy");
              }}
            >
              Privacy Policy
            </p>
            <p
              className="common-footer-ele common-footer-link-ele"
              onClick={() => {
                navigate("/terms-and-conditions");
              }}
            >
              Terms & Conditions
            </p>
          </div>
        </div>
        <div className="contact-con">
          <div>
            <h3>CUSTOMER CARE</h3>
            <p
              className="yellow-text common-footer-link-ele"
              onClick={() => {
                const emailAddress = "customercare@subway.in";
                window.location.href = `mailto:${emailAddress}`;
              }}
            >
              customercare@subway.in
            </p>
          </div>
          <div>
            <h3>FOR VENDOR PARTNERSHIP</h3>
            <p className="yellow-text">partner@subway.in</p>
          </div>
        </div>

        <div className="common-footer-about-us-border"></div>
        <p>
          Registered Address: Eversub India Private Limited, 6th Floor, Vaman
          Centre, Makwana Road Marol, Andheri East, Mumbai-400059, Maharashtra
        </p>
        <p>
          All rights reserved. SUBWAY® is a Registered Trademark of Subway IP
          LLC. ©2022 Subway IP LLC., and is licensed to Eversub India Private
          Limited under an exclusive master franchisee arrangement for the
          territory of the Republic of India.
        </p>

        <img src={logo} className="sm-logo si-hide-mobile" alt="subway" />
      </footer>
      {isUnsubscribeModalOpen && (
        <>
          <Modal
            open={!!isUnsubscribeModalOpen}
            onClose={() => {
              setIsUnsubscribeModalOpen(null);
            }}
            sx={{
              overflow: "scroll",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className="unsubscribe-modal-con">
              <div className="modal-cross-div">
                <CloseIcon
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    fontSize: { md: "40px" },
                  }}
                  onClick={() => setIsUnsubscribeModalOpen(null)}
                />
              </div>

              <Unsubscribe />
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
};

export default CommonFooter;
