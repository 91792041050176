import { Typography } from "@mui/material";
import "../index.css";
import swiggy from "../../../../../resources/images/swiggy.webp";
import zomato from "../../../../../resources/images/zomato.webp";
import magicpin from "../../../../../resources/images/magicpin.webp";
import Swiggy from "../../../../swiggy";
import Zomato from "../../../../zomato";
import Magicpin from "../../../../magicpin";
import { useState } from "react";
const OrderNow = ({ text = "Order now on", isModal = false }) => {
  const [curButton, setCurbutton] = useState(null);
  return (
    <div>
      {isModal ? (
        <div className="modal-menu-oder-btn">
          <Typography
            sx={{ fontWeight: { md: 600 }, fontSize: { md: "20px" } }}
          >
            {text}
          </Typography>
          <div className="order-now-imgs">
            <button
              className="custom-btn swiggy home-order-btn"
              onClick={() => {
                setCurbutton("swiggy");
              }}
            >
              <img src={swiggy} alt="subway" />
            </button>
            <button
              className="custom-btn zomato home-order-btn"
              onClick={() => {
                setCurbutton("zomato");
              }}
            >
              <img src={zomato} alt="subway" />
            </button>
            <button
              className="custom-btn magicpin home-order-btn"
              onClick={() => {
                setCurbutton("magicpin");
              }}
            >
              <img src={magicpin} alt="subway" />
            </button>
          </div>
        </div>
      ) : (
        <div className="menu-order-btn-con">
          <Typography
            sx={{ fontWeight: { md: 700 }, fontSize: { md: "25px" } }}
          >
            {text}
          </Typography>
          <div className="order-now-imgs">
            <button
              className="custom-btn swiggy home-order-btn"
              onClick={() => {
                setCurbutton("swiggy");
              }}
            >
              <img src={swiggy} alt="subway" />
            </button>
            <button
              className="custom-btn zomato home-order-btn"
              onClick={() => {
                setCurbutton("zomato");
              }}
            >
              <img src={zomato} alt="subway" />
            </button>
            <button
              className="custom-btn magicpin home-order-btn"
              onClick={() => {
                setCurbutton("magicpin");
              }}
            >
              <img src={magicpin} alt="subway" />
            </button>
          </div>
        </div>
      )}
      {curButton == "swiggy" && <Swiggy isLoad={false} />}
      {curButton == "zomato" && <Zomato isLoad={false} />}
      {curButton == "magicpin" && <Magicpin isLoad={false} />}
    </div>
  );
};

// const OrderNow = ({ text = 'Order now on' }) => {
//
//   return (
//     <div>
//       <b>{text}</b>
//       <div className="order-now-imgs">
//         <button className='custom-btn swiggy home-order-btn'></button>
//         <img src={swiggy} />
//         <img src={zomato} />
//       </div>
//     </div>
//   );
// };
export default OrderNow;
