import * as React from "react";
import "../../index.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CreateYourOwn from "./create-own";
import SaladAndWraps from "./salad-and-wraps";
import TreatsAndSides from "./treats-and-sides";
import OrderNow from "../order-now";

const SubsDetailsAccordion = () => {
  const [expandedPanel, setExpandedPanel] = React.useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  return (
    <div className="main-accordions">
      <Accordion
        expanded={expandedPanel === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropUpIcon
              sx={{ color: "White", fontSize: { md: "100px", xs: "50px" } }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordian-con"
        >
          <Typography
            sx={{
              fontWeight: { md: 600, xs: 500 },
              fontSize: { md: "4rem" },
            }}
          >
            CREATE YOUR OWN SUB
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateYourOwn />
          <OrderNow />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropUpIcon
              sx={{ color: "White", fontSize: { md: "100px", xs: "50px" } }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordian-con"
        >
          <Typography
            sx={{ fontWeight: { md: 600, xs: 500 }, fontSize: { md: "4rem" } }}
          >
            SALAD & WRAPS
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SaladAndWraps />
          <OrderNow />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expandedPanel === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={
            <ArrowDropUpIcon
              sx={{ color: "White", fontSize: { md: "100px", xs: "50px" } }}
            />
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="accordian-con"
        >
          <Typography
            sx={{ fontWeight: { md: 600, xs: 500 }, fontSize: { md: "4rem" } }}
          >
            TREATS & SIDES
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TreatsAndSides />
          <OrderNow />
        </AccordionDetails>
      </Accordion>
      <div className="extra-green-div"></div>
    </div>
  );
};

export default SubsDetailsAccordion;
