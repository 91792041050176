import bombayGrill from "../../../../../resources/menu-images/part1/BombayGrillDP.webp";
import delhiTikki from "../../../../../resources/menu-images/part1/DelhiTikkiDP.webp";
import threeCheeseMelt from "../../../../../resources/menu-images/part1/ThreeCheeseMeltDP.webp";
import checkenKeema from "../../../../../resources/menu-images/part1/ChickenKeemsDP.webp";
import RoastChickenMeltDP from "../../../../../resources/menu-images/part1/RoastChickenMeltDP.webp";
import EggAndCheese from "../../../../../resources/menu-images/part1/Egg&CheeseDP.webp";

import ChilliCheeseDP from "../../../../../resources/menu-images/part2/ChilliCheeseDP.webp";
import PaneerAchariDP from "../../../../../resources/menu-images/part2/PaneerAchariDP.webp";
import CrunchyMexicanDP from "../../../../../resources/menu-images/part2/CrunchyMexicanDP.webp";
import ChickenTikkaAchariDP from "../../../../../resources/menu-images/part2/ChickenTikkaAchariDP.webp";
import PepperChickenDP from "../../../../../resources/menu-images/part2/PepperChickenDP.webp";
import GreatAmericanBBQ from "../../../../../resources/menu-images/part2/AmericanBBQDP.webp";

export const imageConfig = [
  {
    text: "Bombay Grill",
    image: bombayGrill,
    description:
      "The classic Bombay sandwich in grilled Italian white bread loaded with tomato, cucumber, onion, capsicum, coriander & green chillies chutney and a whole lotta love. ",
    ingredients: "Allergens -  Contains wheat and milk.",
    servingSize: "219 g / 443 kcal.",
  },
  {
    text: "Delhi Tikki",
    image: delhiTikki,
    description:
      "Grilled Italian white bread stuffed with flavourful aloo patty, onion, chilli mayo and creamy cheezy sauce for a desi twist.",
    ingredients: "Allergens - Contains wheat, milk, barley, oats and soy.",
    servingSize: "192 g / 535 kcal.",
  },
  {
    text: "Three Cheese Melt",
    image: threeCheeseMelt,
    description:
      "Have a cheese blast with Three Cheese Grill sandwich. Freshly grilled Italian white bread stuffed with potato patty, lettuce, onion, capsicum and jalapenos, topped with oregano spice mix and the cheesy trio of cheese slice, liquid cheese and mozzarella. ",
    ingredients: "Allergens - Contains wheat, milk, barley, oats and soy.",
    servingSize: "244g/633 Kcal.",
  },
  {
    text: "Chicken Keema",
    image: checkenKeema,
    description:
      "Juicy chicken keema in grilled Italian white bread loaded with onion, chilli mayo and creamy cheezy sauce.  ",
    ingredients:
      "Allergens - Contains wheat, milk, Soy, barley, oats, and nuts.",
    servingSize: "179 g / 508 kcal.",
  },
  {
    text: "Roast Chicken Melt",
    image: RoastChickenMeltDP,
    description: `Smokin' pepper chicken strips stuffed in grilled Italian white bread topped with lettuce, onion, jalapenos, honey mustard, oozing cheese slice and creamy cheezy sauce.`,
    ingredients:
      "Allergens - Contains wheat, milk, Soy, barley, oats and nuts.",
    servingSize: "202 g / 506 kcal.",
  },
  {
    text: "Egg 'n Cheese",
    image: EggAndCheese,
    description: `The classic all-day breakfast. Eggwich is a freshly grilled Italian white bread stuffed with potato omelette, capsicum, onion, topped with oregano spice mix, chilli mayo, liquid cheese, mozzarella and cheese slice for some extra oozing cheesiness. `,
    ingredients: "Allergens - Contains wheat, milk, egg, barley, oats and soy.",
    servingSize: "259 g / 683 kcal.",
  },
];

export const signatureSubsConfig = [
  {
    text: "Chilli Cheese",
    image: ChilliCheeseDP,
    description: `Multigrain, 3-cheese toasted sub with herby aloo patty, jalapeño, onion & capsicum with chilli mayo. `,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "284 g/ 678 kcal.",
  },
  {
    text: "Paneer Achari",
    image: PaneerAchariDP,
    description: `Multigrain toasted sub with chunks of paneer, achari mayo, pickled onion, capsicum, tomato and chilli mayo. `,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "240 g/ 589 kcal.",
  },
  {
    text: "Crunchy Mexican",
    image: CrunchyMexicanDP,
    description: `Multigrain toasted sub with Mexican bean patty, tomato, capsicum, onion, olives, jalapeño, southwest chipotle sauce & crunchy nachos.`,
    ingredients: "Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "295 g/ 702 kcal. ",
  },
  {
    text: "Chicken Tikka Achari",
    image: ChickenTikkaAchariDP,
    description: `Multigrain toasted sub with Tandoori Chicken, achari mayo, pickled onion, capsicum and tomato, topped with chilli mayo for an extra kick. `,
    ingredients: "Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "240 g/ 520 kcal.",
  },
  {
    text: "Pepper Chicken with dip",
    image: PepperChickenDP,
    description: `A flavourful toasted sub loaded with capsicum, olives & jalapeños. This warm sandwich has juicy black pepper chicken paired with creamy Italian sauce and white Italian bread as the base.`,
    ingredients:
      " Allergens - Contains wheat, rye, barley, oats, milk, nuts & soy.",
    servingSize: "189 g/359 kcal.",
  },
  {
    text: "Great American BBQ",
    image: GreatAmericanBBQ,
    description: `Multigrain toasted sub with chicken pepperoni, chicken slice, smoked chicken strips, onion, pickle, capsicum, eggless mayo and barbecue sauce.`,
    ingredients: " Allergens - Contains wheat, rye, barley, oats, milk & soy.",
    servingSize: "253 g/ 539 kcal.",
  },
];
