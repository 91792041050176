import React, { useState, useEffect } from "react";
import "./index.css";
import PdfViewer from "./pdf-viewer";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
  const navigate = useNavigate();
  return (
    <div className="privacy-con">
      <PdfViewer link="https://simpsonbellcorpstorage.blob.core.windows.net/eparts-blob/Final_Subway_India_Privacy_Policy%20(002).docx%20(1)%20-%201712740618500.pdf" />
      <div className="privacy-footer-con">
        <footer className="home-footer">
          <div className="common-footer-con">
            <div>
              <p className="common-footer-header">Contact</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  const emailAddress = "customercare@subway.in";
                  window.location.href = `mailto:${emailAddress}`;
                }}
              >
                Customer Care
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open("https://feedback.subway.in/talktous", "_blank");
                }}
              >
                Feedback
              </p>
            </div>
            <div>
              <p className="common-footer-header">Subway Cares</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  window.open(
                    "https://qnet.blob.core.windows.net/central/Nutritional%20%2B%20Allergen%20Chart-%20Version%202.45%20September%20(formatted)%20-%201727331404351.pdf",
                    "_blank"
                  );
                }}
              >
                Nutrition Information
              </p>
            </div>
            <div>
              <p className="common-footer-header">Legal</p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/privacy-policy");
                }}
              >
                Privacy Policy
              </p>
              <p
                className="common-footer-ele common-footer-link-ele"
                onClick={() => {
                  navigate("/terms-and-conditions");
                }}
              >
                Terms & Conditions
              </p>
            </div>
          </div>
          <div className="common-footer-border"></div>
          <div>
            <p className="footer-text">
              All rights reserved. SUBWAY® is a Registered Trademark of Subway
              IP LLC. ©2022 Subway IP LLC., and is licensed to Eversub India
              Private Limited under an exclusive master franchisee arrangement
              for the territory of the Republic of India.
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
