import React from "react";
import "../index.css";
import { Typography } from "@mui/material";

const DiscoverItemCard = ({ item, setModalDetails }) => {
  return (
    <div className="menu-discover-card-con">
      <img
        onClick={() => {
          setModalDetails(item);
        }}
        src={item.image}
        className="menu-img discover-img"
        alt="subway"
      />
      <Typography variant="h5" className="menu-discover-title">
        {item.text}
      </Typography>
    </div>
  );
};

export default DiscoverItemCard;
