import React from "react";
import { Grid, Typography } from "@mui/material";
import NonVegSub from "../../../../../../resources/menu-images/create-own/NonVegSub.webp";
import VegSub from "../../../../../../resources/menu-images/create-own/VegSub.webp";
import OrderNow from "../order-now";

const CreateYourOwn = () => {
  return (
    <div className="create-your-own">
      <Grid
        container
        md={12}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="float-start">
            <img className="create-own-img" src={VegSub} alt="subway" />
          </div>
          <div>
            <Typography variant="h5" className="menu-dropdown-text">
              Veggie Delite
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Aloo Patty
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Corn & Peas
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Veg Shami
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Paneer Tikka
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Chilli Bean Patty
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Spinach 'n Cheese
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className="float-end">
            <img className="create-own-img" src={NonVegSub} alt="subway" />
          </div>
          <div>
            <Typography variant="h5" className="menu-dropdown-text">
              Cheese Omelete
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Spicy Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Roast Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Peri Peri Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Tandoori Chicken
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Chicken Teriyaki
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Italian B.M.T
            </Typography>
            <Typography variant="h5" className="menu-dropdown-text">
              Tuna
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateYourOwn;
